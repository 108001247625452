import dynamic from 'next/dynamic';
import { Fragment, useState } from 'react';

import MobileNavbar from 'components/Common/MobileNavbar';
import { MobileHomeHeader } from 'components/atomic/atoms';
import {
  AboutSectionMobile,
  BecomePartnerMobile,
  EventGlimpsesMobile,
  FeaturedCategoriesMobile,
  TopBrandsMobile,
  TrendingServices,
  TrustedPartners
} from 'components/atomic/molecules';
import {
  HaflaBlogsMobileOrganism,
  TrendingCollectionOrganism,
  WhyChooseHaflaMobileOrganism
} from 'components/atomic/organism';
import Layout from 'components/layout/DefaultLayout';

const HowDoesItWork = dynamic(
  () => import('components/atomic/molecules/homePage/HowDoesItWork'),
  {
    ssr: false
  }
);

const tempInactive = false;

const MobileHome = ({ bannerTranslation, blogPost, collections }) => {
  const [showSearchModal, setShowSearchModal] = useState(false);

  return (
    <Fragment>
      <MobileHomeHeader
        isHomePage={true}
        showSearchModal={showSearchModal}
      />
      <Layout
        paramData={{
          bannerTranslate: bannerTranslation,
          translate: bannerTranslation,
          eventCategory: 'home',
          homeScreen: true
        }}
      >
        <TopBrandsMobile />
        <TrendingServices />
        <FeaturedCategoriesMobile />
        <TrustedPartners />
        <EventGlimpsesMobile />
        <BecomePartnerMobile />
        <TrendingCollectionOrganism {...{ collectionList: collections }} />
        <WhyChooseHaflaMobileOrganism />
        <HaflaBlogsMobileOrganism posts={blogPost} />
        {tempInactive && <HowDoesItWork {...{ isMobile: true }} />}
        <MobileNavbar
          onPage={'home'}
          showSearchModal={showSearchModal}
          setShowSearchModal={setShowSearchModal}
        />
        <AboutSectionMobile />
      </Layout>
    </Fragment>
  );
};

export default MobileHome;
