import dynamic from 'next/dynamic';
import { Fragment } from 'react';

import {
  AboutSectionWeb,
  BecomePartnerWeb,
  FeaturedCategoriesWeb,
  TopBrandsWeb,
  TrendingServices,
  TrustedPartners
} from 'components/atomic/molecules';
import {
  EventsGlimpsesWebOrganism,
  HaflaBlogOrganism,
  TrendingCollectionOrganism,
  WhyChooseHaflaOrganism
} from 'components/atomic/organism';
import Header from 'components/Common/header/Header';
import Layout from 'components/layout/DefaultLayout';

const HowDoesItWork = dynamic(
  () => import('components/atomic/molecules/homePage/HowDoesItWork'),
  {
    ssr: false
  }
);

const isActive = false;

const WebHome = ({
  bannerTranslation,
  blogPost,
  collections,
  parentCategories
}) => (
  <Fragment>
    <Header
      categories={parentCategories}
      homeScreen={true}
    />
    <Layout
      paramData={{
        bannerTranslate: bannerTranslation,
        eventCategory: 'home',
        homeScreen: true,
        translate: bannerTranslation
      }}
    >
      <TopBrandsWeb />
      <TrendingServices />
      <FeaturedCategoriesWeb />
      <TrustedPartners />
      <EventsGlimpsesWebOrganism />
      <BecomePartnerWeb />
      <TrendingCollectionOrganism {...{ collectionList: collections }} />
      <WhyChooseHaflaOrganism />
      {isActive && <HowDoesItWork />}
      <HaflaBlogOrganism posts={blogPost} />
      <AboutSectionWeb />
    </Layout>
  </Fragment>
);

export default WebHome;
