/*
  #TODO: Use uniform property names to avoid property picking.
    IE:  posts={data.blogPost} should have been posts={data.posts}
  #TODO: Remove unused external calls to improve performance
    Ex: getAllChildCategories.
  #TODO: Export relevant local components as Atoms / Molecules / Organisms.
*/

import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect, useState } from 'react';

import MobileHome from 'components/homePage/Mobile';
import WebHome from 'components/homePage/Web';
import { useIsMobile } from 'helpers/screenResolutionHelper';
import { getBlogsPosts } from 'services/blog.service';
import { getCategories } from 'services/categories.service';
import { getPublishedCollections } from 'services/collection.service';

const Index = ({ blogPost, collections, parentCategories }) => {
  const [isMobileView] = useIsMobile();
  const [parentCategoriesData, setParentCategoriesData] = useState([]);
  const bannerTranslation = useTranslation('homeBanner');

  useEffect(() => {
    if (parentCategories?.length) {
      setParentCategoriesData(parentCategories);
    }
  }, [parentCategories]);

  const Component = isMobileView ? MobileHome : WebHome;

  return (
    <Component
      {...{
        bannerTranslation,
        blogPost,
        collections,
        parentCategories,
        parentCategoriesData
      }}
    />
  );
};

export async function getStaticProps({ locale = 'en' }) {
  // TODO: Streamline the signatures of the functions to cut-down on code volume.
  const values = await Promise.all([
    getBlogsPosts(),
    getPublishedCollections(0, 3),
    getCategories(),
    serverSideTranslations(locale, [
      'homeBanner',
      'event-categories',
      'customerReviewCard',
      'googleReviewCard',
      'industryPartners',
      'haflaWorks',
      'eventGallery',
      'footer'
    ])
  ]);

  return {
    props: {
      blogPost: values[0]?.entity || [],
      collections: values[1]?.entity || [],
      parentCategories: values[2] || [],
      ...values[3]
    },
    revalidate: 900
  };
}

export default Index;
